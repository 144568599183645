.MapSelector{
    position: absolute;
    z-index: 999;
    top: 0px;
    width: 100%;
}

.MapSelectorSub
{
    margin-top: 25px;
    clear: both;
    position: relative;
    display: block;
}

.SelectorIcon{
    padding-top: 6px;
}

.MapDialog{
    position: fixed;
    top :0;
    left:0;
    right:0;
    bottom:0;
    color: white;
    outline: none;
    padding: 3.2rem;
    text-align: center;
}