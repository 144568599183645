.DetailsModule{
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    bottom: 0;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 10px;
    width: 90%;
    transform: translate(2%, -20%);
    font-family: inherit;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.MapTime
{
    font-size: 14px;
    font-weight: normal;
}

.SliderSelector{
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.TimeDisplay{
    color: #212121;
    font-size: 13px;
    font-family: inherit;
    margin-top: 5px;
}

.SliderModule{
    flex: auto;
}

.DateModule{
    flex: auto;
}

input[type=range] {
    width: 100%;
    margin: 6.15px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    background: #17427F;
    border: 0px solid #17427F;
    border-radius: 1.3px;
    width: 100%;
    height: 2.7px;
    cursor: pointer;
  }
  input[type=range]::-webkit-slider-thumb {
    margin-top: -6.35px;
    width: 15px;
    height: 15px;
    background: #17427F;
    border: 1.5px solid #17427F;
    border-radius: 38px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #17427F;
  }
  input[type=range]::-moz-range-track {
    background: #17427F;
    border: 0px solid #010101;
    border-radius: 1.3px;
    width: 100%;
    height: 2.7px;
    cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #17427F;
    border: 1.5px solid #17427F;
    border-radius: 38px;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 6.15px 0;
    color: transparent;
    width: 100%;
    height: 2.7px;
    cursor: pointer;
  }
  input[type=range]::-ms-fill-lower {
    background: #17427F;
    border: 0px solid #010101;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-fill-upper {
    background: #17427F;
    border: 0px solid #010101;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-thumb {
    width: 15px;
    height: 15px;
    background: #17427F;
    border: 1.5px solid #17427F;
    border-radius: 38px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #17427F;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #17427F;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

  .Info {

    position: absolute !important;
    /*top: 10%;*/
    transform: translate(10px, 70px);
    z-index: 999;
    font-family: inherit;
  }

  .InfoSection{
    padding: 6px 6px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 14px;
  }

  .InfoSectionUnit{
    padding: 6px 6px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 14px !important;
    margin-top: 5px;
  }
  
  .Info h4 {
    margin: 0 0 5px;
    color: #777;
  }
  
  .Legend {
    text-align: left;
    line-height: 15px;
    color: #555;
  }
  
  .Legend i {
    width: 15px;
    height: 15px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }

  .leaflet-control-layers label {
    text-align: left !important;
}

.leaflet-control-layers-base {  text-align: left; }

.MapControlModule{
    position: absolute;
    z-index: 999;
    background-color: #E0E0E0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 7px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    opacity: .8;
    padding: 5px 10px;
  }

  /* Gets applied if backdrop-filter supported only */
@supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
  .MapControlModule {
    background-color: rgba(255, 255, 255, 0.3);
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  }

  .ControlTextContainer{
    text-align: left;
    font-size: 1rem;
    color: #000;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ControlText{
    font-weight: 500;
  }

  .ControlTextTime{
    margin-left: 10px;
    font-weight: 300;
  }

  .ControlButtonContainer{
    display: flex;
    flex-direction: row;
  }

  .SliderControl{
    width: 100%;
  }

  .ButtonIcons {
    display: flex;
    min-width: 100px;
  }

  .ButtonIcons span{
    margin-right: 7px;
}